import { useContext } from "react";
import { useIdleTimer } from "react-idle-timer";
import { authContext } from "contexts/AuthContext";
import moment from "moment";

const SessionTimeout = () => {
  const { auth, setAuthData } = useContext(authContext);
  // const logoutTime = 1000 * 5 * 1; // For seconds
  const logoutTime = 60 * 1000 * 30; // For minut
  // const logoutTime = 3600 * 1000 * 1; // For an hour
  const isTokenExpired = (expiryTime) => {
    const expDate = new Date(expiryTime * 1000)
    const currentDate = new Date()
    if (currentDate.getTime() > expDate.getTime()) {
      return true
    }
    return false;
  };
  const handleOnAction = () => {
    const tokenExpiredTime = auth && auth.data ? auth.data.exp : null;
    if (isTokenExpired(tokenExpiredTime)) {

      setAuthData(null);
    }
  };
  const handleOnIdle = () => {
    setAuthData(null);
  };
  useIdleTimer({
    timeout: logoutTime,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500,
  });
  return null;
};
export default SessionTimeout;
