import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Login from "pages/Login";
import Signup from "pages/Signup";
import OtpVerification from "pages/OtpVerification";
import ProtectedRoute from "components/ProtectedRoute";
import ForgotPassword from "components/ResetPasswordPage/ForgotPassword";
import ResetPassword from "components/ResetPasswordPage/ResetPassword";
import PageNotFound from "pages/PageNotFound";
import App from "pages/App";
import InvalidTab from "components/SecurityFeatures/InvalidTab";
import AppVersion from "components/AppVersion";
import { version } from "utils/version.json";
import Login1 from "pages/Login1";

const AppContainer = () => {
  // eslint-disable-next-line no-console
  // console.info('App version: ', version);
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/" component={Login} /> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/login1" component={Login1} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/otp" component={OtpVerification} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/page-not-found" component={PageNotFound} />
        <Route exact path="/not-valid-tab" component={InvalidTab} />
        <Route exact path="/version" component={AppVersion} />
        <ProtectedRoute component={() => <App />} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppContainer;
