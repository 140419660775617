import React, { useContext, useState } from 'react';
import { Alert, Button, Col, Row } from 'reactstrap';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Background from 'assets/background-image.png';

import Logo from 'assets/logo.svg';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';

import { authContext } from 'contexts/AuthContext';
import { LOGIN_SUCCESS } from 'components/common/FSBToastify/types';
import { FORGOT_PASSWORD } from 'components/ResetPasswordPage/ForgotPassword/ForgotPassGraphQL';
import LoginForm from './components/LoginForm';
import { LOGIN as MUTATION_LOGIN } from './LoginGraphQL';

const validationSchema = Yup.object({
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

const Login1 = () => {
  const { setAuthData } = useContext(authContext);
  const [loginErrorMsg, setLoginErrorMsg] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { resetPasswordUserEmail } = location;

  const [login] = useMutation(MUTATION_LOGIN, {
    // eslint-disable-next-line no-shadow
    onCompleted: ({ login }) => {
      toast.success(LOGIN_SUCCESS);
      setLoginErrorMsg([]);
      setAuthData(login);
      history.push('/');
    },
    onError: ({ graphQLErrors }) => {
      const errorMessages = graphQLErrors.map(
        ({ message, extensions: { code } }) => {
          if (code === 'INTERNAL_SERVER_ERROR') return message;
          return '';
        }
      );
      setLoginErrorMsg(errorMessages.toString());
    },
  });

  // forgot passwod mutation
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      history.push({
        pathname: '/login',
        resetPasswordUserEmail,
      });
    },
    onError: ({ graphQLErrors }) => {
      const { message } = graphQLErrors[0];
      toast.error(message);
    },
  });

  const handleLogin = async (values) => {
    try {
      await login({
        variables: values,
      });
    } catch (e) {
      //console.error(e);
    }
  };

  const handleResetPasswordLink = async () => {
    await forgotPassword({
      variables: resetPasswordUserEmail,
    });
  };
  return (
    <Row className="m-0 login-card">
      <Col xs={3}>
        <div className="px-5 py-3">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="">
          <div className="form-signin p-5 mt-5" id="login_container">
            <div className="w-100 h-100 border-0">
              <div>
                <div>
                  <h3 className="login-signup-font">Log In</h3>
                  {resetPasswordUserEmail ? (
                    <Alert color="primary">
                      Check your Email for a link to reset password. If it does
                      not apear within few minutes, check your spam folder or
                      <Button
                        color="link"
                        className="btn btn-md btn-link p-0 pl-1"
                        onClick={handleResetPasswordLink}
                      >
                        click here to request a new link.
                      </Button>
                    </Alert>
                  ) : null}
                </div>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleLogin}
                  validationSchema={validationSchema}
                  children={(props) => (
                    <LoginForm errorMsg={loginErrorMsg} {...props} />
                  )}
                />
              </div>
              <hr className="hr-text" data-content="OR" />

              <div className="p-3 text-center footer-signup">
                <hr />
                Dont have an account?
                <Link className="btn btn-md btn-link p-0 pl-1" to="signup">
                  Register Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col xs={9}>
        <img className="w-100 h-100" src={Background} alt="" />
      </Col>
    </Row>
  );
};

export default Login1;
