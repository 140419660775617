/* eslint-disable react/display-name */
import React from 'react';
import { Redirect } from 'react-router-dom';

const OneTabAuth = (Component) => (props) => {
  const { isDuplicatedWindow } = props;
  if (isDuplicatedWindow) {
    return <Redirect to="/not-valid-tab" />;
  }
  return <Component {...props} />;
};
export default OneTabAuth;
