export const checkUserRole = (data, requiredRoles) => {
  if (requiredRoles) {
    const { role } = data;
    const roleIncluded = requiredRoles.some((r) => role.includes(r));
    if (data && roleIncluded) return true;
  } else if (!requiredRoles) {
    if (data) return true;
  }
  return false;
};

