import { gql } from '@apollo/client';

export const SIGNUP = gql`
  mutation signUpUser(
    $name: String!
    $email: String!
    $password: String!
    $phone: String
  ) {
    signUpUser(
      input: { name: $name, email: $email, password: $password, phone: $phone }
    ) {
      name
    }
  }
`;
