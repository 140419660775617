import React, { useState, useEffect } from "react";
import { APP_TOKEN } from "../constants";

export const authContext = React.createContext({});
const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ loading: true, data: null });

  const setAuthData = (data) => {
    setAuth({ data });
  };

  useEffect(() => {
    setAuth({
      loading: false,
      data: JSON.parse(window.localStorage.getItem(APP_TOKEN)),
    });
  }, []);
  //2. if object with key 'authData' exists in localStorage, we are putting its value in auth.data and we set loading to false.
  //This function will be executed every time component is mounted (every time the user refresh the page);

  useEffect(() => {
    if (!auth.loading)
      window.localStorage.setItem(APP_TOKEN, JSON.stringify(auth.data));
  }, [auth.data, auth.loading]);
  // 1. when **auth.data** changes we are setting **auth.data** in localStorage with the key 'authData'.

  return (
    <authContext.Provider value={{ auth, setAuthData }}>
      {children}
    </authContext.Provider>
  );
};
export default AuthProvider;
