export const LOGIN_SUCCESS = "You are successfully logged in";
export const LOGIN_ERROR = "Your username or password is incorrect";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const RECORD_ADDED_SUCCESSFULLY = "Record added successfully.";
export const RECORD_DELETED_SUCCESSFULLY = "Record deleted successfully.";
export const RECORD_UPDATED_SUCCESSFULLY = "Record updated successfully.";
export const TAG_ADDED_SUCCESSFULLY = "Record updated successfully.";
export const TAG_DELTED_SUCCESSFULLY = "Record updated successfully.";
export const MERGE_TAG_ADDED_SUCCESSFULLY = "Record updated successfully.";
export const MERGE_TAG_DELTED_SUCCESSFULLY = "Record updated successfully.";
export const PROMO_NOTIFICATION = "Record updated successfully.";
export const AUTHOR_RESTRICTED = "Record updated successfully.";
export const REMOVE_DEVICES = "Record updated successfully.";
export const SUBSCRIPTION_ADDED = "Record updated successfully.";
export const SUBSCRIPTION_REMOVED = "Record updated successfully.";
export const SERVICE_PLANS = "Record updated successfully.";
export const SERVICE_PLANS_DELTED = "Record updated successfully.";
export const MEDIA_ADDED = "Record updated successfully.";
export const POST_CREATED = "Record updated successfully.";
export const UPDATE_SEARCH_FEED = "Search feed updated successfully.";
export const POST_SAVE = "Post saved successfully.";
export const POST_DELETE = "Post deleted successfully.";
