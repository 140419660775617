/*eslint no-duplicate-imports: ["error", { "includeExports": true }]*/
import React, { PureComponent } from 'react';
import AppContainer from 'containers/AppContainer';
import './App.css';

class App extends PureComponent {
  render() {
    return <AppContainer />;
  }
}

export default App;
