import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { authContext } from 'contexts/AuthContext';

const InvalidTab = ({ isDuplicatedWindow }) => {
  const history = useHistory();
  const { auth } = useContext(authContext);
  const { data } = auth;

  useEffect(() => {
    if (!isDuplicatedWindow) {
      if (data) {
        history.push('/dashboard');
      } else {
        history.push('/login');
      }
    }
  }, []);

  return (
    <div className="err-container text-center">
      <div className="err">
        <h2>Sorry, the session is already active in another tab</h2>
      </div>
    </div>
  );
};

export default InvalidTab;
