/* eslint-disable no-console */
// eslint-disable-next-line no-console
import React, { useContext, useState } from "react";
import { Alert, Button, Card, CardBody } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Logo from "assets/rati-logo.png";
import * as Yup from "yup";
import { Formik } from "formik";
import { useMutation } from "@apollo/client";

import { authContext } from "contexts/AuthContext";
import { FORGOT_PASSWORD } from "components/ResetPasswordPage/ForgotPassword/ForgotPassGraphQL";
import LoginForm from "./components/LoginForm";

const validationSchema = Yup.object({
  username: Yup.string("Enter your email")
    // .email('Enter a valid email')
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const initialValues = {
  username: "",
  password: "",
};

const Login = () => {
  const { setAuthData } = useContext(authContext);
  const [loginErrorMsg, setLoginErrorMsg] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const { resetPasswordUserEmail } = location;

  // const [login] = useMutation(MUTATION_LOGIN, {
  //   // eslint-disable-next-line no-shadow
  //   onCompleted: ({ login }) => {
  //     toast.success(LOGIN_SUCCESS);
  //     setLoginErrorMsg([]);
  //     setAuthData(login);
  //     history.push("/");
  //   },
  //   onError: ({ graphQLErrors }) => {
  //     const errorMessages = graphQLErrors.map(
  //       ({ message, extensions: { code } }) => {
  //         if (code === "INTERNAL_SERVER_ERROR") return message;
  //         return "";
  //       }
  //     );
  //     setLoginErrorMsg(errorMessages.toString());
  //   },
  // });

  // forgot passwod mutation
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      history.push({
        pathname: "/login",
        resetPasswordUserEmail,
      });
    },
    onError: ({ graphQLErrors }) => {
      const { message } = graphQLErrors[0];
      toast.error(message);
    },
  });

  const handleLogin = async (values) => {
    const response = await fetch(`${window.REST_ENDPOINT}/authenticate`, {
      method: "POST",

      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response) {
      const tempResponse = await response.json();
      if (tempResponse && tempResponse.token) {
        setAuthData({
          token: tempResponse.token,
          userName: tempResponse.payload.username,
          exp: tempResponse.payload.exp,
          name: tempResponse.payload.name,
          sub: tempResponse.payload.sub,
          role: "Admin",
        });
        history.push("/");
      } else {
        toast.error("Invalid username or password");
      }
    }
  };

  const handleResetPasswordLink = async () => {
    await forgotPassword({
      variables: resetPasswordUserEmail,
    });
  };
  return (
    <div className="no-auth-main-container">
      <div className="form-signin" id="login_container">
        <div className="text-center">
          <img className="rati-logo" src={Logo} alt="Logo" />
        </div>
        <div className="text-center">
          <div className="wc-title mt-2">WELCOME TO</div>
          <div className="rati-title ">
            <span className="dark-title ">RATI</span>
            <span className="light-title ">BEAUTY</span>
          </div>
        </div>
        <Card className="w-100 border-0">
          <CardBody>
            <div className="text-center">
              <h3>Log In</h3>
              {resetPasswordUserEmail ? (
                <Alert color="primary">
                  Check your Email for a link to reset password. If it does not
                  apear within few minutes, check your spam folder or
                  <Button
                    color="link"
                    className="btn btn-md btn-link p-0 pl-1"
                    onClick={handleResetPasswordLink}
                  >
                    click here to request a new link.
                  </Button>
                </Alert>
              ) : null}
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleLogin}
              validationSchema={validationSchema}
              children={(props) => (
                <LoginForm errorMsg={loginErrorMsg} {...props} />
              )}
            />
          </CardBody>
          {/* <hr />
          <div className="p-3 text-center">
            Dont have an account?
            <Link className="btn btn-md btn-link p-0 pl-1" to="signup">
              Register Now
            </Link>
          </div> */}
        </Card>
      </div>
    </div>
  );
};

export default Login;
