import React from 'react';
import { Field } from 'formik';
import { InputGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import FormikInputField from 'components/Fields/FormikInputField';
import LoadingButton from 'components/common/LoadingButton';
import InlineErrorMessage from 'components/common/InlineErrorMessage';

const LoginForm = (props) => {
  const {
    handleSubmit,
    isValid,
    isSubmitting,
    handleBlur,
    errorMsg = '',
  } = props;
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <InputGroup size="medium">
          <Field
            id="login_email"
            name="email"
            type="email"
            component={FormikInputField}
            label="Email"
            placeholder="Email"
            onBlur={handleBlur}
          />
        </InputGroup>
        <InputGroup size="medium">
          <Field
            id="login_password"
            name="password"
            type="password"
            component={FormikInputField}
            label="Password"
            placeholder="Password"
            onBlur={handleBlur}
          />
        </InputGroup>

        <InlineErrorMessage errorMsg={errorMsg} />
        <div className="">
          <Link className="small" to="/forgot-password">
            <i className="fa fa-lock" /> Forgot Password{' '}
          </Link>
        </div>
        <LoadingButton
          className="mt-2 btn btn-primary btn-block"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
          color="primary"
          block
          type="submit"
          size="lg"
          id="login_btn"
        >
          Login
        </LoadingButton>
      </form>
    </div>
  );
};
export default LoginForm;
