import React from 'react';
import { Button } from 'reactstrap';
import OtpInput from 'react-otp-input';

const OtpVerificationForm = (props) => {
  const { onSubmit, otp, onResendClick, handleChange } = props;
  const numInputs = 6;
  return (
    <>
      <div className="otp-input">
        <OtpInput
          className="test"
          value={otp}
          onChange={handleChange}
          numInputs={numInputs}
          inputStyle="inputStyle"
          separator={<span>-</span>}
        />
      </div>
      <Button
        onClick={onSubmit}
        disabled={otp.length < numInputs}
        className="mt-4 pl-3 pr-3"
        color="primary"
        size="lg"
        block
        type="submit"
      >
        Validate OTP
      </Button>
      <Button
        onClick={onResendClick}
        className="mt-2"
        color="link"
        size="md"
        block
      >
        Resend OTP
      </Button>
    </>
  );
};

export default OtpVerificationForm;
