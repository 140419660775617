import React, { useState, useEffect } from "react";
import { APP_TOKEN } from "../constants";

export const tabContext = React.createContext({});
const TabProvider = ({ children }) => {
  const [tab, setTab] = useState({ loading: true, data: "image" });

  const setTabData = (data) => {
    setTab({ data });
  };

  return (
    <tabContext.Provider value={{ tab, setTabData }}>
      {children}
    </tabContext.Provider>
  );
};
export default TabProvider;
