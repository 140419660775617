import React from "react";
import { Button, Spinner } from "reactstrap";
import classnames from "classnames";

const LoadingButton = ({ children, loading, block, ...rest }) => (
  <Button {...rest} block={block}>
    {!(block && !loading) && (
      <Spinner
        className={classnames({
          "position-relative": true,
          "btn-loader": !block,
          "d-inline-block": loading,
          "d-none": !loading,
        })}
        size="sm"
      />
    )}
    {!(block && loading) && (
      <span
        className={classnames({
          "d-none": loading,
          "d-inline-block": !loading,
          "span-style": !block,
        })}
      >
        {children}
      </span>
    )}
  </Button>
);
export default LoadingButton;
