import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Card, CardBody } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { FORGOT_PASSWORD } from './ForgotPassGraphQL';

const validationSchema = Yup.object({
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
});

const initialValues = {
  email: '',
};

const ForgotPassword = () => {
  const history = useHistory();
  const [userEmail, setUserEmail] = useState({});
  // forgotPassword mutation
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      history.push({
        pathname: '/login',
        resetPasswordUserEmail: userEmail,
      });
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message, extensions: { code } }) => {
        if (code === 'INTERNAL_SERVER_ERROR') toast.error(message);
      });
    },
  });

  // handle forgot password submit and call mutation to sent email
  const handleForgotPassword = async (values) => {
    try {
      setUserEmail(values);
      await forgotPassword({
        variables: values,
      });
    } catch (e) {}
  };

  return (
    <div className="no-auth-main-container">
      <div className="form-signin">
        <Card className="w-100 border-0">
          <CardBody>
            <div>
              <h3 className="login-signup-font">Forgot Password</h3>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleForgotPassword}
              validationSchema={validationSchema}
              children={(props) => <ForgotPasswordForm {...props} />}
            />
          </CardBody>
        </Card>
        <div className="p-3 text-center">
          Back to
          <Link className="btn btn-md btn-link p-0 pl-1" to="/login">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
