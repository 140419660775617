import React from 'react';
import { Card, CardBody } from 'reactstrap';
import version from 'utils/version.json';

const AppVersion = () => {
  return (
    <div>
      <Card>
        <CardBody>Branch: {version.branch}</CardBody>
        <CardBody>Build Date: {version.build_date}</CardBody>
        <CardBody>Commit Id: {version.commit_id}</CardBody>
        <CardBody>App Version: {version.version}</CardBody>
      </Card>
    </div>
  );
};

export default AppVersion;
