import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="text-center">
          <h1 className="font-weight-bold text-uppercase">Oops...</h1>
          <h6 className="f-weight-6 text-uppercase text-info">
            something went wrong Here
          </h6>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
