import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from 'assets/logo.svg';
import OtpVerificationForm from './components/OtpVerificationForm';

const OtpVerification = () => {
  const [otp, setOtp] = useState('');
  const handleChange = (otpNumber) => setOtp(otpNumber);

  const onSubmit = async () => {};

  const onResendClick = async () => {};
  return (
    <div className="no-auth-main-container">
      <div className="form-signin">
        <Card className="w-100">
          <CardBody>
            <div className="text-center">
              <img src={Logo} alt="Logo" />
              <hr />
              <h3>OTP Verification</h3>
              <p className="small">
                Enter OTP sent on your verified email address and reset your
                password.
              </p>
              <OtpVerificationForm
                otp={otp}
                handleChange={handleChange}
                onResendClick={onResendClick}
                onSubmit={onSubmit}
              />
            </div>
          </CardBody>
        </Card>
        <div className="p-3 text-center">
          Dont have an account?
          <Link className="btn btn-md btn-link p-0 pl-1" to="signup">
            Register Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OtpVerification;
