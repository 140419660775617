/* eslint-disable no-plusplus */
import React from 'react';

const isDuplicatedWindow = function (
  localStorageTimeout,
  localStorageResetInterval,
  localStorageTabKey
) {
  const ItemType = {
    Session: 1,
    Local: 2,
  };

  function setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    window.document.cookie = `${name}=${value || ''}${expires}; path=/`;
  }

  function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = window.document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  function GetItem(itemtype) {
    let val = '';

    if (itemtype === ItemType.Session) {
      val = window.name;
    }
    if (itemtype === ItemType.Local) {
      val = decodeURIComponent(getCookie(localStorageTabKey));
      // eslint-disable-next-line eqeqeq
      if (val == undefined) val = '';
    }

    return val;
  }

  function SetItem(itemtype, val) {
    if (itemtype === ItemType.Session) {
      window.name = val;
    }

    if (itemtype === ItemType.Local) {
      setCookie(localStorageTabKey, val);
    }
  }

  function createGUID() {
    const s4 = function () {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  function isTabDuplicated() {
    const sessionGuid = GetItem(ItemType.Session) || createGUID();
    SetItem(ItemType.Session, sessionGuid);

    const val = GetItem(ItemType.Local);
    // eslint-disable-next-line eqeqeq
    const tabObj = (val == '' ? null : JSON.parse(val)) || null;

    if (
      tabObj === null ||
      tabObj.timestamp < new Date().getTime() - localStorageTimeout ||
      tabObj.guid === sessionGuid
    ) {
      const setTabObj = () => {
        const newTabObj = {
          guid: sessionGuid,
          timestamp: new Date().getTime(),
        };
        SetItem(ItemType.Local, JSON.stringify(newTabObj));
      };

      setTabObj();
      setInterval(setTabObj, localStorageResetInterval);
      window.onunload = () => {
        SetItem(ItemType.Local, '');
        localStorage.removeItem(localStorageTabKey);
      };
      return false;
    }
    return true;
  }
  return isTabDuplicated();
};

export function WithOneTabEnforcer({
  localStorageTimeout = 15 * 1000,
  localStorageResetInterval = 10 * 1000,
  appName = 'gold-code-default-tab-enforce',
} = {}) {
  return (WrappedComponent) => {
    // eslint-disable-next-line react/display-name
    return (props) => {
      if (
        isDuplicatedWindow(
          localStorageTimeout,
          localStorageResetInterval,
          appName
        )
      ) {
        return <WrappedComponent {...props} isDuplicatedWindow />;
      }
      return <WrappedComponent {...props} />;
    };
  };
}
