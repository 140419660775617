import React from 'react';
import { Field } from 'formik';
import { InputGroup } from 'reactstrap';
import FormikInputField from 'components/Fields/FormikInputField';
import LoadingButton from 'components/common/LoadingButton';

const ForgotPasswordForm = ({ handleSubmit, isValid, dirty, isSubmitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      <InputGroup className="mt-3" size="medium">
        <Field
          id="email"
          name="email"
          component={FormikInputField}
          label="Enter Your Email Address"
          placeholder="Email"
        />
      </InputGroup>

      <LoadingButton
        className="mt-2 mb-2 btn btn-primary btn-block"
        loading={isSubmitting}
        disabled={!isValid || !dirty || isSubmitting}
        color="primary"
        block
        type="submit"
        size="lg"
      >
        Submit
      </LoadingButton>
    </form>
  );
};

export default ForgotPasswordForm;
