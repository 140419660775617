import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Card, CardBody } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Logo from 'assets/logo.svg';
import { useMutation } from '@apollo/client';
import { RECORD_ADDED_SUCCESSFULLY } from 'components/common/FSBToastify/types';
import { toast } from 'react-toastify';
import { SIGNUP } from './SignupGraphQL';
import SignupForm from './components/SignupForm';

const validationSchema = Yup.object({
  name: Yup.string('Enter your name').required('Name is required'),
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  phone: Yup.number('Enter your phone number'),
  password: Yup.string()
    .required('This field is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirm is required'),
});
const initialValues = {
  name: '',
  email: '',
  password: '',
  phone: '',
};

const Signup = () => {
  const history = useHistory();
  // Signup mutation
  const [signUpUser] = useMutation(SIGNUP, {
    onCompleted: () => {
      toast.success(RECORD_ADDED_SUCCESSFULLY);
      history.push('/login');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message, extensions: { code } }) => {
        if (code === 'INTERNAL_SERVER_ERROR') toast.error(message);
      });
    },
  });

  // Handle signup form submit and call mutation to add user on db
  const handleSignup = async (values) => {
    await signUpUser({
      variables: values,
    });
  };

  return (
    <div className="no-auth-main-container">
      <div className="form-signin" id="signup_container">
        <div className="text-center">
          <img src={Logo} alt="Logo" />
        </div>
        <Card className="w-100 border-0">
          <CardBody>
            <div className="text-center">
              <h3>Sign Up</h3>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSignup}
              validationSchema={validationSchema}
              children={(props) => <SignupForm {...props} />}
            />
          </CardBody>
        </Card>
        <div className="p-3 text-center">
          Already have an account?
          <Link className="btn btn-md btn-link p-0 pl-1" to="/login">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
