import React from 'react';
import { Field } from 'formik';
import { InputGroup } from 'reactstrap';
import FormikInputField from 'components/Fields/FormikInputField';
import LoadingButton from 'components/common/LoadingButton';
import PasswordStrengthMeter from 'components/PasswordStrengthMeter';
import { security } from 'config/securityFeatures';

const ResetPasswordForm = (props) => {
  const { handleSubmit, isValid, dirty, isSubmitting, values } = props;
  const { password } = values;
  return (
    <form onSubmit={handleSubmit}>
      <InputGroup className="mt-3" size="medium">
        <Field
          id="password"
          name="password"
          type="password"
          component={FormikInputField}
          label="New Password"
          placeholder="Enter Password"
        />
      </InputGroup>

      <InputGroup size="medium">
        <Field
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          component={FormikInputField}
          label="Confirm Password"
          placeholder="Confirm Password"
        />
      </InputGroup>
      {/* password strength bar will render here...... 
      The password strength bar imported from components/PasswordStrengthMeter --
       in case password strength bar needs in any other file or project it needs to be there to import */}
      {password && security.showPasswordStrengthBar && (
        <PasswordStrengthMeter password={password} />
      )}

      <LoadingButton
        className="mt-2 btn btn-primary btn-block"
        loading={isSubmitting}
        disabled={!isValid || !dirty || isSubmitting}
        color="primary"
        block
        type="submit"
        size="lg"
      >
        Change My Password
      </LoadingButton>
    </form>
  );
};

export default ResetPasswordForm;
