import React, { useState } from 'react';
import { Progress } from 'reactstrap';

/* The password strength meter used on signup, reset-password and update-password
add/import in any project/file to use this feature
 */
const passwordCases = {
  hasNumber: false,
  hasLowerCase: false,
  hasUpperCase: false,
  hasSpecialChar: false,
  has8Char: false,
};

const PasswordStrengthMeter = ({ password }) => {
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [matchedPasswordCase, setMatchedPasswordCase] = useState(passwordCases);
  const { hasNumber, hasLowerCase, hasUpperCase, hasSpecialChar, has8Char } =
    matchedPasswordCase;

  if (!has8Char && password.length >= 8) {
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      has8Char: true,
    });
    setPasswordStrengthScore(passwordStrengthScore + 1);
  } else if (has8Char && password.length < 8) {
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      has8Char: false,
    });
    setPasswordStrengthScore(passwordStrengthScore - 1);
  }

  if (!hasLowerCase && password.match('(?=.*[a-z])')) {
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      hasLowerCase: true,
    });
    setPasswordStrengthScore(passwordStrengthScore + 1);
  } else if (hasLowerCase && !password.match('(?=.*[a-z])')) {
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      hasLowerCase: false,
    });
    setPasswordStrengthScore(passwordStrengthScore - 1);
  }

  if (!hasUpperCase && password.match('(?=.*[A-Z])')) {
    setPasswordStrengthScore(passwordStrengthScore + 1);
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      hasUpperCase: true,
    });
  } else if (hasUpperCase && !password.match('(?=.*[A-Z])')) {
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      hasUpperCase: false,
    });
    setPasswordStrengthScore(passwordStrengthScore - 1);
  }
  if (!hasNumber && password.match('(?=.*\\d)')) {
    setPasswordStrengthScore(passwordStrengthScore + 1);
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      hasNumber: true,
    });
  } else if (hasNumber && !password.match('(?=.*\\d)')) {
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      hasNumber: false,
    });
    setPasswordStrengthScore(passwordStrengthScore - 1);
  }

  if (!hasSpecialChar && password.match('[-+_!@#$%^&*.,?]')) {
    setPasswordStrengthScore(passwordStrengthScore + 1);
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      hasSpecialChar: true,
    });
  } else if (hasSpecialChar && !password.match('[-+_!@#$%^&*.,?]')) {
    setMatchedPasswordCase({
      ...matchedPasswordCase,
      hasSpecialChar: false,
    });
    setPasswordStrengthScore(passwordStrengthScore - 1);
  }

  const createPasswordLabel = (result) => {
    switch (result) {
      case 0:
        return { label: 'Weak', color: 'danger' };
      case 1:
        return { label: 'Weak', color: 'danger' };
      case 2:
        return { label: 'Fair', color: 'warning' };
      case 3:
        return { label: 'Good', color: 'info' };
      case 4:
        return { label: 'Good', color: 'info' };
      case 5:
        return { label: 'Strong', color: 'success' };
      default:
        return { label: 'Weak', color: 'danger' };
    }
  };

  const labelColor = createPasswordLabel(passwordStrengthScore);
  return (
    <Progress
      bar
      color={labelColor.color}
      value={passwordStrengthScore}
      max="5"
    >
      {labelColor.label}
    </Progress>
  );
};

export default PasswordStrengthMeter;
