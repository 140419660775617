import React from 'react';
import { Card, CardBody } from 'reactstrap';
import * as Yup from 'yup';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { Formik } from 'formik';
import Logo from 'assets/logo.svg';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import ResetPasswordForm from './components/ResetPasswordForm';
import { RESET_PASSWORD } from './ResetPasswordGraphQL';

const validationSchema = Yup.object({
  password: Yup.string()
    .required('This field is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirm is required'),
});

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ResetPassword = () => {
  const { search } = useLocation();
  const history = useHistory();

  const urlStr = search.replaceAll('+', '_plus_');
  let token = new URLSearchParams(urlStr).get('token');
  token = token ? token.replaceAll('_plus_', '+') : '';

  // resetPassword mutation
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      toast.success('Your password was sucessfully updated.');
      history.push('/login');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.forEach(({ message, extensions: { code } }) => {
        if (code === 'INTERNAL_SERVER_ERROR') toast.error(message);
      });
    },
  });

  const handleResetPassword = async (values) => {
    const { password } = values;
    const data = {
      newPassword: password,
      token,
    };
    await resetPassword({
      variables: data,
    });
  };
  return (
    <div className="no-auth-main-container">
      <div className="form-signin">
        <Card className="w-100">
          <CardBody>
            <div className="text-center">
              <img src={Logo} alt="Logo" />
              <hr />
              <h3>Change Password</h3>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={handleResetPassword}
              validationSchema={validationSchema}
              children={(props) => <ResetPasswordForm {...props} />}
            />
          </CardBody>
        </Card>
        <div className="p-3 text-center">
          Back to
          <Link className="btn btn-md btn-link p-0 pl-1" to="/login">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
