import React, { useState, Suspense, lazy } from 'react';
import ErrorBoundary from 'components/common/ErrorBoundary';
import SessionTimeout from 'components/SessionTimeout';
import { WithOneTabEnforcer } from 'components/SecurityFeatures/WithOneTabEnforcer';
import OneTabAuth from 'components/SecurityFeatures/OneTabAuth';
import { security } from 'config/securityFeatures';

const Sidebar = lazy(() => import('components/Sidebar'));
const Header = lazy(() => import('components/common/Header'));
const Content = lazy(() => import('./component/Content'));

const App = () => {
  const [sidebar, setSidebar] = useState(true);

  return (
    <>
      <ErrorBoundary>
        <SessionTimeout />
        <Suspense fallback={<div>Header Loading...</div>}>
          <Header sidebar={sidebar} setSidebar={setSidebar} />
        </Suspense>
      </ErrorBoundary>
      <div className="main-wrapper d-flex">
        <aside
          className={
            sidebar ? 'left-sidebar sidebar-show' : 'left-sidebar sidebar-hide'
          }
        >
          <div className="d-flex sidebar-container flex-column">
            <ErrorBoundary>
              <Suspense fallback={<div>Sidebar Loading...</div>}>
                <Sidebar />
              </Suspense>
            </ErrorBoundary>
          </div>
        </aside>

        <div className={`page-wrapper flex-fill pt-2 ${sidebar ? '' : 'pl-0'}`}>
          <div className="page-content container-fluid">
            <ErrorBoundary>
              <Suspense fallback={<div>Content Loading...</div>}>
                <Content />
              </Suspense>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </>
  );
};

const appHolder = security.enableTabEnforcerSecurity
  ? WithOneTabEnforcer()(OneTabAuth(App))
  : App;
export default appHolder;
