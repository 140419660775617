import React, { useState } from "react";
import { Field } from "formik";
import { InputGroup } from "reactstrap";
import FormikInputField from "components/Fields/FormikInputField";
import LoadingButton from "components/common/LoadingButton";
import InlineErrorMessage from "components/common/InlineErrorMessage";

const LoginForm = (props) => {
  const {
    handleSubmit,
    isValid,
    isSubmitting,
    handleBlur,
    errorMsg = "",
  } = props;
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <InputGroup size="medium">
          <Field
            id="login_username"
            name="username"
            type="text"
            component={FormikInputField}
            label="Username"
            placeholder="Username"
            onBlur={handleBlur}
          />
        </InputGroup>
        <InputGroup size="medium">
          <Field
            id="login_password"
            name="password"
            type={currentPasswordShown ? "text" : "password"}
            component={FormikInputField}
            label="Password"
            placeholder="Password"
            onBlur={handleBlur}
          />
          <div className="input-group-addon">
            <i
              aria-label="show password"
              role="button"
              onClick={() => {
                setCurrentPasswordShown(!currentPasswordShown);
              }}
              className={
                currentPasswordShown
                  ? "fa fa-eye fa-lg"
                  : "fa fa-eye-slash fa-lg"
              }
            />
          </div>
        </InputGroup>

        <InlineErrorMessage errorMsg={errorMsg} />
        {/* <div className="">
          <Link className="small" to="/forgot-password">
            <i className="fa fa-lock" /> Forgot Password{' '}
          </Link>
        </div> */}
        <LoadingButton
          className="mt-2 btn btn-primary btn-block"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
          color="primary"
          block
          type="submit"
          size="lg"
          id="login_btn"
        >
          Login
        </LoadingButton>
      </form>
    </div>
  );
};
export default LoginForm;
