import React from 'react';

const Error404 = () => (
  <div className="err-container text-center">
    <div className="err">
      <h1>404</h1>
      <h2>Sorry, page not found</h2>
    </div>
  </div>
);

// eslint-disable-next-line react/no-multi-comp
const PageNotFound = () => (
  <div className="page-err">
    <div key="1">
      <Error404 />
    </div>
  </div>
);

export default PageNotFound;
