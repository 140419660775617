import React from 'react';
import { Field } from 'formik';
import { InputGroup } from 'reactstrap';
import FormikInputField from 'components/Fields/FormikInputField';
import LoadingButton from 'components/common/LoadingButton';
import PasswordStrengthMeter from 'components/PasswordStrengthMeter';
import { security } from 'config/securityFeatures';

const SignupForm = (props) => {
  const { handleSubmit, isValid, dirty, isSubmitting, values } = props;
  const { password } = values;
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <InputGroup className="mt-3" size="medium">
          <Field
            id="signup_name"
            name="name"
            component={FormikInputField}
            label="Name"
            placeholder="Name"
          />
        </InputGroup>

        <InputGroup className="mt-3" size="medium">
          <Field
            id="signup_email"
            name="email"
            component={FormikInputField}
            label="Email"
            placeholder="Email"
          />
        </InputGroup>

        <InputGroup className="mt-3" size="medium">
          <Field
            id="signup_phone"
            name="phone"
            component={FormikInputField}
            label="Phone"
            placeholder="Phone"
          />
        </InputGroup>

        <InputGroup className="mt-3" size="medium">
          <Field
            id="signup_password"
            name="password"
            type="password"
            component={FormikInputField}
            label="Password"
            placeholder="Password"
          />
        </InputGroup>

        <InputGroup size="medium">
          <Field
            id="signup_confirmPassword"
            name="confirmPassword"
            type="password"
            component={FormikInputField}
            label="Confirm Password"
            placeholder="Password"
          />
        </InputGroup>
        {/* password strength bar will render here...... 
      The password strength bar imported from components/PasswordStrengthMeter --
       in case password strength bar needs in any other file or project it needs to be there to import */}
        {password && security.showPasswordStrengthBar && (
          <PasswordStrengthMeter password={password} />
        )}

        <LoadingButton
          className="mt-2 mb-2 btn btn-primary btn-block"
          loading={isSubmitting}
          disabled={!isValid || !dirty || isSubmitting}
          color="primary"
          block
          type="submit"
          size="lg"
          id="signup_btn"
        >
          Sign Up
        </LoadingButton>
      </form>
    </div>
  );
};

export default SignupForm;
